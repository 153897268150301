import posthog from "posthog-js";

export function initAnalytics() {
  console.log("Init PH");
  posthog.init("phc_SchO0fA6DPQmXOmOsycPycNZ4Dbw8pDLk87iObcz3d5", {
    api_host: "https://us.i.posthog.com",
    person_profiles: "identified_only",
    autocapture: false,
    capture_pageview: false,
    enable_heatmaps: false,
  });
}

export function trackPageView() {
  posthog.capture("$pageview");
}

export function identifyUser(
  userId: string,
  email: string | null,
  displayName: string
) {
  posthog.identify(userId, { email, name: displayName });
}

export function trackClientEvent(
  event: string,
  properties: Record<string, any>
) {
  console.log("trackClientEvent", event, properties);
  posthog.capture(event, properties);
}
